const OrderStatus = {
  NEW: 'new',
  NEW_PREDICTED: 'new_predicted',
  OPEN_PENDING_VENDOR_REG: 'pending_vendor_registration',
  PENDING_APPROVAL: 'pending_approval',
  OPEN: 'open',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  RETURNED: 'returned',
  REJECTED: 'rejected'
};

export default Object.freeze(OrderStatus);
