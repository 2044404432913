export default Object.freeze({
  NEW: 'new',
  NEW_PREDICTED: 'new_predicted',
  RETURNED: 'returned',
  PENDING_AUTHORIZER: 'pending_customer_autorizer',
  PENDING_PURCHASER: 'pending_customer_purchaser',
  PENDING_APPROVAL: 'pending_approval',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  CANCELLED: 'cancelled'
});
