import { VAT_PERCENT } from '../constants';

const BusinessType = {
  AuthorizedDealer: 'AUTHORIZED_DEALER',
  Company: 'LIMITED_COMPANY',
  NGO: 'NGO',
  NGOVAT: 'NGOVAT',
  ExemptDealer: 'EXEMPT_DEALER',
  CorporateWithPartnership: 'PARTNERSHIP',
  EntityLess: 'ENTITYLESS',
  EntityLessWithVat: 'ENTITYLESS_VAT'
};

export const BusinessTypeVatAmount = {
  [BusinessType.AuthorizedDealer]: VAT_PERCENT,
  [BusinessType.Company]: VAT_PERCENT,
  [BusinessType.NGO]: 0,
  [BusinessType.NGOVAT]: VAT_PERCENT,
  [BusinessType.ExemptDealer]: 0,
  [BusinessType.CorporateWithPartnership]: VAT_PERCENT,
  [BusinessType.EntityLess]: 0,
  [BusinessType.EntityLessWithVat]: VAT_PERCENT
};

export default Object.freeze(BusinessType);
